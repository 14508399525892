<template>
  <div class="container">
    <AppBreadcrumbs />

    <div class="brands__wrapper">
      <h1 class="brands__heading heading heading--4">
        <span>{{ t('All brands') }}</span>
      </h1>

      <section class="brands__alphabet">
        <ul class="brands__alphabet--list">
          <li
            v-for="letter in 'ABCDEFGHIJKLMNOPQRSTUVWXYZ#'.split('')"
            :key="letter"
          >
            <button
              v-if="brandsData?.[letter]?.name"
              class="brands__alphabet--button"
              @click="scrollToLetter(anchorRefs[brandsData[letter].name])"
            >
              <span>{{ letter }}</span>
            </button>
            <span
              v-else
              class="brands__alphabet--label"
            >
              <span>{{ letter }}</span>
            </span>
          </li>
        </ul>
      </section>

      <section class="brands__grid">
        <div
          v-for="(item, i) in brandsData"
          :ref="(el) => (anchorRefs[item.name] = el)"
          :key="i"
          class="brands__grid__column"
        >
          <div class="brands__grid__column--heading">
            <span>{{ item.name }}</span>
          </div>
          <ul class="brands__grid__column--menu">
            <li
              v-for="(brand, j) in item.brands"
              :key="j"
            >
              <AppLink
                :href="'/' + brand.url"
                class="brands__grid__column--link"
              >
                {{ brand.label }}
              </AppLink>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n({ useScope: 'global' })
const { setBreadcrumbs } = useBreadcrumbs()
const { pageView } = useDataLayer()
const anchorRefs = ref({})
const brandsData = ref<any[]>([])
const { fetchBrands, brands } = useBrands()

setBreadcrumbs({
  label: t('All brands'),
})

pageView('brands')

const metaTitle = computed(
  () => t('All brands'),
)

const metaDescription = computed(
  () => metaTitle.value + ' | ' + t('nuxtSiteConfig.description'),
)

useHead({
  title: metaTitle,
  meta: [
    { hid: 'description', name: 'description', content: metaDescription },
    { hid: 'robots', name: 'robots', content: 'index, follow' },
  ],
})

const loadBrands = async () => {
  await fetchBrands()
  brandsData.value = [...(brands.value?.brandsList?.urls || [])].sort((a, b) => {
    const aValue = a.name?.toUpperCase() // ignore casing
    const bValue = b.name?.toUpperCase() // ignore casing
    if (aValue < bValue) {
      return -1
    }
    if (aValue > bValue) {
      return 1
    }
    return 0
  }).reduce(function (groups, brand) {
    const firstLetter = brand.name?.toUpperCase()?.charAt(0)
    if (!groups[firstLetter]) {
      groups[firstLetter] = {
        name: firstLetter,
        brands: [],
      }
    }

    groups[firstLetter].brands.push(brand)

    return groups
  }, {})
}
loadBrands()

const scrollToLetter = (el: HTMLElement) => {
  el.scrollIntoView({
    behavior: 'smooth',
  })
}
</script>

<style src="~layers/app/pages/~brands.css" />
