import brandsQuery from '~layers/app/graphql/brands.gql'

export function useBrands() {
  const error = ref<string>('')
  const brands = ref<any[]>([])
  const loading = ref<boolean>(false)

  const fetchBrands = async () => {
    loading.value = true

    const result = await useAsyncQuery<{
      brands: { brandsList: any[] }
    }>({
      query: brandsQuery,
    })
    error.value = result.error.value

    brands.value = result.data.value || []
    loading.value = false
  }

  return {
    error,
    fetchBrands,
    loading,
    brands,
  }
}
